<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          filterable
          @change="didChange"
          placeholder="请选择部门"
          style="width: 100%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检查人" prop="fqrEid">
        <el-select
          v-model="dataForm.fqrEid"
          clearable
          filterable
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择检查人' : '请先选择部门'"
          @change="eidChange"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检查时间" prop="checkDate">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.checkDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="date"
          placeholder="选择日期"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="检查地点" prop="checkAddress">
        <el-input
          v-model="dataForm.checkAddress"
          placeholder="检查地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="检查内容" prop="checkContent">
        <el-input
          v-model="dataForm.checkContent"
          placeholder="检查内容"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="dataForm.id" label="检查情况" prop="jcqk">
        <editor ref="editor1" v-model="dataForm.jcqk"></editor>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="不合格原因" prop="bhgyy">
        <el-input v-model="dataForm.bhgyy" placeholder="不合格原因"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      employeeList: [],
      departmentList: [],
      visible: false,
      dataForm: {
        id: 0,
        type: 0,
        fqrEid: "",
        fqrName: "",
        checkDate: "",
        checkAddress: "",
        checkContent: "",
        did: "",
        dname: "",
        jcqk: "",
        bhgyy: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        fqrEid: [
          { required: true, message: "检查人不能为空", trigger: "blur" },
        ],
        checkDate: [
          { required: true, message: "检查时间不能为空", trigger: "blur" },
        ],
        checkAddress: [
          { required: true, message: "检查地点不能为空", trigger: "blur" },
        ],
        checkContent: [
          { required: true, message: "检查内容不能为空", trigger: "blur" },
        ],
        did: [{ required: true, message: "部门不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }
      });
    },
    // 选择的部门发生变化
    didChange(e) {
      if (e) {
        this.getEmployeeList();

        this.dataForm.dname = "";

        // 获取当前选择的部门名字
        let result = this.departmentList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.dname = result[0].name;
        }
      } else {
        this.employeeList = [];
        this.dataForm.fqrEid = "";
      }
    },
    // 选择的员工发生变化
    eidChange(e) {
      this.dataForm.fqrName = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.employeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.fqrName = result[0].name;
        }
      }
    },
    // 获取员工列表
    getEmployeeList() {
      let employeeListDF = {
        did: this.dataForm.did,
        page: 1,
        limit: 99999,
      };

      this.$http.employee.list(employeeListDF).then((res) => {
        if (res.data) {
          this.employeeList = res.data.list || [];
        } else {
          this.employeeList = [];
        }
      });
    },
    init(id) {
      this.employeeList = [];
      this.departmentList = [];
      this.getDepartmentList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.supervise.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;

              this.$refs.editor1.destroy();
              this.$refs.editor1.init(this.dataForm.jcqk);
            }
          });
        } else {
          this.$refs.editor1.destroy();
          this.$refs.editor1.init(this.dataForm.jcqk);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.supervise.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.supervise.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
