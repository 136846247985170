<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        width="80"
        :index="
          (e) => {
            return (e + 1) * pageIndex;
          }
        "
        align="center"
        label="序号"
        fixed="left"
      >
      </el-table-column>
      <el-table-column
        prop="dname"
        header-align="center"
        align="center"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="fqrName"
        header-align="center"
        align="center"
        label="检查人"
      >
      </el-table-column>
      <el-table-column
        prop="checkDate"
        header-align="center"
        align="center"
        label="检查时间"
      >
      </el-table-column>
      <el-table-column
        prop="checkAddress"
        header-align="center"
        align="center"
        label="检查地点"
      >
      </el-table-column>
      <el-table-column
        prop="checkContent"
        header-align="center"
        align="center"
        label="检查内容"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./supervise-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        type: 0,
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.supervise.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
     // 删除
     deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.supervise
          .delete({
            id,
            remarks: val,
          })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
